<template>
  <div>
    <div class="row">
      <div class="col-12 mx-auto">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('MENU.SALARY.DRIVER_PREM') }}
              </h3>
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex">
              <div class="col-4">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="start_date | dateFilter"
                      dense
                      label="Premiyani Sanadan"
                      append-icon="event"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="start_date"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </div>

              <div class="col-4">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="end_date | dateFilter"
                      dense
                      label="Sanagacha  "
                      append-icon="event"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="end_date"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="col-2">
                <div class="card-toolbar">
                  <div
                    type="button"
                    v-on:click="postDate"
                    class="w-100 btn btn-primary font-weight-bolder"
                  >
                    <span class="svg-icon svg-icon-white"
                      ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Navigation/Check.svg--><svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                            transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "
                          />
                        </g></svg
                      ><!--end::Svg Icon--></span
                    >

                    {{ $t('PAGES.CHECK') }}
                  </div>
                </div>
              </div>
              <div class="col-2">
                <div v-on:click="postDateClean" type="button">
                  <div class="w-100 btn btn-warning font-weight-bolder">
                    <span class="svg-icon svg-icon-white"
                      ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Code/Backspace.svg--><svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <path
                            d="M8.42034438,20 L21,20 C22.1045695,20 23,19.1045695 23,18 L23,6 C23,4.8954305 22.1045695,4 21,4 L8.42034438,4 C8.15668432,4 7.90369297,4.10412727 7.71642146,4.28972363 L0.653241109,11.2897236 C0.260966303,11.6784895 0.25812177,12.3116481 0.646887666,12.7039229 C0.648995955,12.7060502 0.651113791,12.7081681 0.653241109,12.7102764 L7.71642146,19.7102764 C7.90369297,19.8958727 8.15668432,20 8.42034438,20 Z"
                            fill="#000000"
                            opacity="0.3"
                          />
                          <path
                            d="M12.5857864,12 L11.1715729,10.5857864 C10.7810486,10.1952621 10.7810486,9.56209717 11.1715729,9.17157288 C11.5620972,8.78104858 12.1952621,8.78104858 12.5857864,9.17157288 L14,10.5857864 L15.4142136,9.17157288 C15.8047379,8.78104858 16.4379028,8.78104858 16.8284271,9.17157288 C17.2189514,9.56209717 17.2189514,10.1952621 16.8284271,10.5857864 L15.4142136,12 L16.8284271,13.4142136 C17.2189514,13.8047379 17.2189514,14.4379028 16.8284271,14.8284271 C16.4379028,15.2189514 15.8047379,15.2189514 15.4142136,14.8284271 L14,13.4142136 L12.5857864,14.8284271 C12.1952621,15.2189514 11.5620972,15.2189514 11.1715729,14.8284271 C10.7810486,14.4379028 10.7810486,13.8047379 11.1715729,13.4142136 L12.5857864,12 Z"
                            fill="#000000"
                          />
                        </g></svg
                      ><!--end::Svg Icon--></span
                    >

                    {{ $t('BREADCRUMBS.CLEAN') }}
                  </div>
                </div>
              </div>
            </div>
            <v-data-table
              :headers="headers"
              :items="getData"
              disable-pagination
              hide-default-footer
              single-select
              @click:row="rowClick"
              item-key="id"
              class="row-pointer"
              no-data-text="Maʼlumot kiritilmagan"
            >
              <template v-slot:[`item.delivered_zayavkas_amount`]="{ item }">
                {{ item.delivered_zayavkas_amount.toLocaleString('zh-ZH') }}
              </template>

              <template v-slot:[`item.full_name`]="{ item }">
                {{
                  item.staff_description.staffpersonal_description.first_name
                }}

                {{ item.staff_description.staffpersonal_description.last_name }}
              </template>

              <template v-slot:[`item.index`]="{ item }">
                {{
                  getData
                    .map(function (x) {
                      return x.id
                    })
                    .indexOf(item.id) + 1
                }}
              </template>

              <template
                v-slot:[`item.delivered_zayavkas_premiye_total`]="{ item }"
              >
                <div v-if="item.delivered_zayavkas_premiye_total == null">
                  {{ 0 }}
                </div>
                <div v-else>
                  {{
                    item.delivered_zayavkas_premiye_total.toLocaleString(
                      'zh-ZH'
                    )
                  }}
                </div>
              </template>
              <template v-slot:[`item.staff_description`]="{ item }">
                {{ item.staff_description.employed_from | formatDate }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <div style="text-align: right !important">
                  <action :index="item"></action>
                </div>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import action from './Action'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  components: { action },
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      start_date: '',
      end_date: '',
      menu1: false,
      modal1: false,
      modal2: false,
      menu2: false
    }
  },
  beforeCreate() {
    this.$store.dispatch('getAllDriversRevenues')
  },
  computed: {
    headers() {
      return [
        {
          text: '#',
          value: 'index'
        },
        {
          text: this.$t('TABLE_HEADER.STAFF_NAME_SURNAME'),
          value: 'full_name'
        },
        {
          text: this.$t('TABLE_HEADER.EMPLOYED_FROM'),
          value: 'staff_description'
        },
        {
          text: this.$t('TABLE_HEADER.DELIVERED_ZAYAVKAS_AMOUNT'),
          value: 'delivered_zayavkas_amount'
        },
        {
          text: this.$t('TABLE_HEADER.DELIVERED_ZAYAVKAS_PREMIYE_TOTAL'),
          value: 'delivered_zayavkas_premiye_total'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    },
    getData() {
      return this.$store.state.requests.allDriversRevenues
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        row.select(false)
      }
    },
    postDate() {
      if (this.start_date !== '' && this.end_date !== '') {
        const data = {
          start_date: this.start_date,
          end_date: this.end_date
        }

        this.$store.dispatch('getAllDriversRevenuesFromToDate', data)
      }
    },
    postDateClean() {
      this.start_date = ''
      this.end_date = ''
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.SALARY.TITLE') },
      { title: this.$t('MENU.SALARY.DRIVER_PREM') }
    ])
  }
}
</script>

<style scoped>
.card.card-custom > .card-header {
  align-items: unset !important;
}
.card-header {
  display: flex;
  align-items: center;
}
.card-button div {
  display: inline-block;
}
</style>
